<template>
  <div>
    <transition name="fade">
      <div>
        <div class="pc">
          <div class="card-wrapper" v-if="cards.length>0">
            <div v-for="(card, idx) in cards" :key="'card'+idx">
              <div class="unselect" @click="clickDetail(card)">
                <div class="card-img" :style="imgField(card.imgs[0], 'pc')"></div>
                <div class="card-content">
                  <!-- 파트너 -->
                  <div class="flex-align margin-top-12" v-if="card.partner">
                    <img style="width:32px;height:32px"
                         class="margin-right-8"
                         :src="card.partner.img" v-if="card.partner.img">
                    <div class="body4 sub3">{{ card.partner.name }}</div>
                  </div>
                  <div class="subtitle4 main margin-top-8">{{ card.name }}</div>
                  <div class="body3 sub2 ellipsis-1">{{ card.simple_desc }}</div>
                  <!-- 가격 정보 -->
                  <div v-if="discountedPrice(card)>0" class="margin-top-8">
                    <div class="body5 sub4 text-line-through" v-if="isDiscount(card)">{{ card.price.price | currency }}</div>
                    <div class="flex-align">
                      <div class="margin-right-4 primary body0-bold" v-if="card.price.discount_type===0">{{ discountText(card) }}</div>
                      <div class="body0-bold">
                        <span class="sub">{{ discountedPrice(card) | currencyNum }}</span>
                        <span class="body4 sub2">원</span>
                        <span class="body4 sub3" v-if="card.price.is_subscription">/월</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mobile">
          <div class="card-wrapper" v-if="cards.length>0">
            <div :class="{'margin-top-40': idx>0, 'margin-top-24': idx===0}" v-for="(card, idx) in cards" :key="'card'+idx">
              <div @click="clickDetail(card)">
                <div class="card-img" :style="imgField(card.imgs[0], 'mobile')"></div>
                <div class="card-content">
                  <!-- 파트너 -->
                  <div class="flex-align" v-if="card.partner">
                    <img style="width:24px;height:24px"
                         class="margin-right-8"
                         :src="card.partner.img" v-if="card.partner.img">
                    <div class="body5 sub3">{{ card.partner.name }}</div>
                  </div>
                  <div class="subtitle6 main margin-top-8">{{ card.name }}</div>
                  <div class="body5 sub2 ellipsis-1">{{ card.simple_desc }}</div>
                  <!-- 가격 정보 -->
                  <div v-if="discountedPrice(card)>0">
                    <div class="body5 sub4 text-line-through" v-if="isDiscount(card)">{{ card.price.price | currency }}</div>
                    <div class="flex-align">
                      <div class="margin-right-4 primary body2-bold" v-if="card.price.discount_type===0">{{ discountText(card) }}</div>
                      <div class="body2-bold">
                        <span class="sub">{{ discountedPrice(card) | currencyNum }}</span>
                        <span class="body4 sub2">원</span>
                        <span class="body4 sub3" v-if="card.price.is_subscription">/월</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    name: "CardProductPlugin",
    props: {
      products: {
        type: Array
      },
      shadow: {
        type: Boolean,
        default: false
      }
    },
    created() {
      this.cards = this.products;
    },
    data() {
      return {
        cards: [],
        avail_color: [],
        colors: ['#0ead9d', '#d0ae45', '#f45853', '#393e46', '#ff6600'],
        ishover: false
      }
    },
    watch: {
      products(n) {
        this.cards = n;
      }
    },
    computed: {
      boxShadow() {
        if (this.shadow) return 'box-shadow: 0px 2px 10px 0 #ccc8c8;';
        else return '';
      }
    },
    methods: {
      discountText(product) {
        let text = '';
        if (this.isDiscount(product)) {
          if (product.price.discount_rate !== 0 && product.price.discount_type === 0) {
            text = product.price.discount_rate + '%'
          } else if (product.price.discount_price !== 0 && product.price.discount_type === 1) {
            text = product.price.discount_price + '원';
          }
        }
        return text;
      },
      // 할인 여부
      isDiscount(product) {
        if (product.price.is_discount && product.price.discount_start !== null && product.price.discount_end !== null) {
          let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
          return !(currentDate < product.price.discount_start || currentDate > product.price.discount_end);
        } else if (product.price.discount_start !== null && product.price.discount_end === null) {
          let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
          return currentDate >= product.price.discount_start;
        } else if (product.price.discount_start === null && product.price.discount_end !== null) {
          let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
          return currentDate <= product.price.discount_end;
        } else {
          return false;
        }
      },
      // 상품 가격
      discountedPrice(product) {
        let total = 0;
        if (this.isDiscount(product)) {
          if (product.price.discount_rate !== 0 && product.price.discount_type === 0) {
            total = product.price.price * (1 - (product.price.discount_rate / 100))
          } else if (product.price.discount_price !== 0 && product.price.discount_type === 1) {
            total = product.price.price - product.price.discount_price;
          }
        } else total = product.price.price;
        return total;
      },
      // 상세 페이지 이동
      clickDetail(card) {
        this.routeGa('', `테마 카드(${card.name})`, card.name);
        if (this.$route.path.search('detail') > -1) {
          this.$router.push(`/store/detail?id=${card.id}`)
        } else {
          this.$router.push(`store/detail?id=${card.id}`)
        }
      },
      imgField(img, device) {
        let style = {
          backgroundImage: `url(${this.image(img)})`,
          backgroundSize: 'cover'
        };
        if(device==='pc') {
          style.backgroundPosition = 'center';
        } else {
          style.backgroundPosition = 'top';
        }
        return style;
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .pc
    .card-wrapper
      display grid
      grid-gap 57px 24px
      grid-template-columns repeat(3, 1fr)

    .card-img
      position relative
      width 100%
      height 368px
      border-radius 8px
      background-color $gray4
      border 1px solid $gray3

    .card-content
      padding-top 12px

  .mobile
    .card-img
      position relative
      width 100%
      height 240px
      border-radius 8px
      background-color $gray4
      border 1px solid $gray3

    .card-content
      padding-top 12px

</style>
